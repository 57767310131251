export const statueList = [
  { value: "1", label: "待审批" },
  { value: "2", label: "审批通过" },
  { value: "3", label: "审批不通过" }
];

export const typeList = [
  { value: "1", label: "上线" },
  { value: "0", label: "下线" }
];

export const typeDic = { 1: "上线", 0: "下线" };

export const iconConf = {
  1: { name: "daishenpi", color: "#F68446" },
  2: { name: "shenhetongguo", color: "#8EB433" },
  3: { name: "shenpibutongguo", color: "#9B9B9B" }
};

export const approveList = [
  { label: "同意", value: 1 },
  { label: "不同意", value: 0 }
];
