<template>
  <div class="two-line-approve">
    <van-nav-bar left-arrow title="审批" @click-left="$router.back()" />
    <van-form
      ref="van-form"
      :show-error-message="false"
      label-width="50vw"
      :class="['van-form__text-color']"
    >
      <van-field
        required
        :rules="[{ required: true }]"
        label="审批意见"
        name="passStatus"
      >
        <template #input>
          <van-radio-group
            v-model="info.approvalOpinion"
            direction="horizontal"
          >
            <van-radio
              v-for="item in approveList"
              :key="item.value"
              :name="item.value"
              >{{ item.label }}</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="info.approvalRemark"
        rows="2"
        autosize
        label="备注"
        type="textarea"
        maxlength="500"
        placeholder="请输入内容"
        show-word-limit
      />
    </van-form>
    <div class="details-page__footer-button-wrapper--fixed">
      <!--      <van-button class="two-btn cancel-btn" @click="$router.back()"-->
      <!--        >取消</van-button-->
      <!--      >-->
      <van-button
        style="color: #fff"
        type="info"
        class="two-btn"
        @click="onApprove"
        >提交</van-button
      >
    </div>
  </div>
</template>

<script>
import { approveList } from "@/views/TwoLine/utils/constant";
import { approveTwoLine, getPageTwoLineLog } from "@/api/warning";
import { operateMessage } from "@/utils";

export default {
  name: "TwoLineApprove",
  props: ["id"],
  data() {
    return {
      approveList,
      info: {
        logId: "",
        approvalOpinion: 1,
        approvalRemark: "",
        approvalUser: "",
        operation: "",
        groupId: ""
      },
      logInfo: {}
    };
  },
  created() {
    this.getLog();
  },
  methods: {
    getLog() {
      getPageTwoLineLog(this.id).then(res => {
        this.logInfo = res?.list?.[0] ?? {};
      });
    },
    onApprove() {
      this.info.groupId = this.id;
      this.info.logId = this.logInfo.id;
      this.info.approvalUser = this.userInfo.id;
      this.info.operation = this.logInfo.operation;
      approveTwoLine(this.info).then(res => {
        operateMessage(!!res, "提交");
        res && this.$router.back();
      });
    }
  }
};
</script>

<style lang="scss">
.two-line-approve {
  .two-btn {
    border-radius: 27px;
  }
  .cancel-btn {
    margin-right: 13px;
    color: #5d5f63;
    border: 1px solid #e1e3e8;
  }
}
</style>
